// Import Fonts

@font-face {
  font-family: 'BrownStd Bold';
  src: url('/fonts/BrownStd-Bold.eot');
  src: url('/fonts/BrownStd-Bold.woff2') format('woff2'),
    url('/fonts/BrownStd-Bold.woff') format('woff'),
    url('/fonts/BrownStd-Bold.ttf') format('truetype'),
    url('/fonts/BrownStd-Bold.svg') format('svg'),
    url('/fonts/BrownStd-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Bold Alt';
  src: url('/fonts/BrownStd-BoldAlt.eot');
  src: url('/fonts/BrownStd-BoldAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-BoldAlt.woff') format('woff'),
    url('/fonts/BrownStd-BoldAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-BoldAlt.svg') format('svg'),
    url('/fonts/BrownStd-BoldAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Bold Italic';
  src: url('/fonts/BrownStd-BoldItalic.eot');
  src: url('/fonts/BrownStd-BoldItalic.woff2') format('woff2'),
    url('/fonts/BrownStd-BoldItalic.woff') format('woff'),
    url('/fonts/BrownStd-BoldItalic.ttf') format('truetype'),
    url('/fonts/BrownStd-BoldItalic.svg') format('svg'),
    url('/fonts/BrownStd-BoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Bold Italic Alt';
  src: url('/fonts/BrownStd-BoldItalicAlt.eot');
  src: url('/fonts/BrownStd-BoldItalicAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-BoldItalicAlt.woff') format('woff'),
    url('/fonts/BrownStd-BoldItalicAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-BoldItalicAlt.svg') format('svg'),
    url('/fonts/BrownStd-BoldItalicAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Italic';
  src: url('/fonts/BrownStd-Italic.eot');
  src: url('/fonts/BrownStd-Italic.woff2') format('woff2'),
    url('/fonts/BrownStd-Italic.woff') format('woff'),
    url('/fonts/BrownStd-Italic.ttf') format('truetype'),
    url('/fonts/BrownStd-Italic.svg') format('svg'),
    url('/fonts/BrownStd-Italic.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Italic Alt';
  src: url('/fonts/BrownStd-ItalicAlt.eot');
  src: url('/fonts/BrownStd-ItalicAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-ItalicAlt.woff') format('woff'),
    url('/fonts/BrownStd-ItalicAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-ItalicAlt.svg') format('svg'),
    url('/fonts/BrownStd-ItalicAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Italic Alt';
  src: url('/fonts/BrownStd-ItalicAlt.eot');
  src: url('/fonts/BrownStd-ItalicAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-ItalicAlt.woff') format('woff'),
    url('/fonts/BrownStd-ItalicAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-ItalicAlt.svg') format('svg'),
    url('/fonts/BrownStd-ItalicAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Light';
  src: url('/fonts/BrownStd-Light.eot');
  src: url('/fonts/BrownStd-Light.woff2') format('woff2'),
    url('/fonts/BrownStd-Light.woff') format('woff'),
    url('/fonts/BrownStd-Light.ttf') format('truetype'),
    url('/fonts/BrownStd-Light.svg') format('svg'),
    url('/fonts/BrownStd-Light.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Light Italic';
  src: url('/fonts/BrownStd-LightItalic.eot');
  src: url('/fonts/BrownStd-LightItalic.woff2') format('woff2'),
    url('/fonts/BrownStd-LightItalic.woff') format('woff'),
    url('/fonts/BrownStd-LightItalic.ttf') format('truetype'),
    url('/fonts/BrownStd-LightItalic.svg') format('svg'),
    url('/fonts/BrownStd-LightItalic.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Bold';
  src: url('/fonts/BrownStd-ReclinBold.eot');
  src: url('/fonts/BrownStd-ReclinBold.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinBold.woff') format('woff'),
    url('/fonts/BrownStd-ReclinBold.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinBold.svg') format('svg'),
    url('/fonts/BrownStd-ReclinBold.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Bold Alt';
  src: url('/fonts/BrownStd-ReclinBoldAlt.eot');
  src: url('/fonts/BrownStd-ReclinBoldAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinBoldAlt.woff') format('woff'),
    url('/fonts/BrownStd-ReclinBoldAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinBoldAlt.svg') format('svg'),
    url('/fonts/BrownStd-ReclinBoldAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Bold Alt';
  src: url('/fonts/BrownStd-ReclinBoldAlt.eot');
  src: url('/fonts/BrownStd-ReclinBoldAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinBoldAlt.woff') format('woff'),
    url('/fonts/BrownStd-ReclinBoldAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinBoldAlt.svg') format('svg'),
    url('/fonts/BrownStd-ReclinBoldAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Light';
  src: url('/fonts/BrownStd-ReclinLight.eot');
  src: url('/fonts/BrownStd-ReclinLight.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinLight.woff') format('woff'),
    url('/fonts/BrownStd-ReclinLight.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinLight.svg') format('svg'),
    url('/fonts/BrownStd-ReclinLight.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Regular';
  src: url('/fonts/BrownStd-ReclinRegular.eot');
  src: url('/fonts/BrownStd-ReclinRegular.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinRegular.woff') format('woff'),
    url('/fonts/BrownStd-ReclinRegular.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinRegular.svg') format('svg'),
    url('/fonts/BrownStd-ReclinRegular.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Regular Alt';
  src: url('/fonts/BrownStd-ReclinRegularAlt.eot');
  src: url('/fonts/BrownStd-ReclinRegularAlt.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinRegularAlt.woff') format('woff'),
    url('/fonts/BrownStd-ReclinRegularAlt.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinRegularAlt.svg') format('svg'),
    url('/fonts/BrownStd-ReclinRegularAlt.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Reclin Thin';
  src: url('/fonts/BrownStd-ReclinThin.eot');
  src: url('/fonts/BrownStd-ReclinThin.woff2') format('woff2'),
    url('/fonts/BrownStd-ReclinThin.woff') format('woff'),
    url('/fonts/BrownStd-ReclinThin.ttf') format('truetype'),
    url('/fonts/BrownStd-ReclinThin.svg') format('svg'),
    url('/fonts/BrownStd-ReclinThin.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Regular';
  src: url('/fonts/BrownStd-Regular.eot');
  src: url('/fonts/BrownStd-Regular.woff2') format('woff2'),
    url('/fonts/BrownStd-Regular.woff') format('woff'),
    url('/fonts/BrownStd-Regular.ttf') format('truetype'),
    url('/fonts/BrownStd-Regular.svg') format('svg'),
    url('/fonts/BrownStd-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Thin';
  src: url('/fonts/BrownStd-Thin.eot');
  src: url('/fonts/BrownStd-Thin.woff2') format('woff2'),
    url('/fonts/BrownStd-Thin.woff') format('woff'),
    url('/fonts/BrownStd-Thin.ttf') format('truetype'),
    url('/fonts/BrownStd-Thin.svg') format('svg'),
    url('/fonts/BrownStd-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'BrownStd Thin Italic';
  src: url('/fonts/BrownStd-ThinItalic.otf') format('opentype');
  src: url('/fonts/BrownStd-ThinItalic.eot');
  src: url('/fonts/BrownStd-ThinItalic.woff2') format('woff2'),
    url('/fonts/BrownStd-ThinItalic.woff') format('woff'),
    url('/fonts/BrownStd-ThinItalic.ttf') format('truetype'),
    url('/fonts/BrownStd-ThinItalic.svg') format('svg'),
    url('/fonts/BrownStd-ThinItalic.otf') format('opentype');
}
